import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, NavLink, Switch, Redirect, useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { AppContext } from "../ContextApi";
import { NotokenFound, handleTokenInResponse } from "../Utils";
import { LuLogOut } from "react-icons/lu";
import { mainAPikey } from "../ContextApi";
import { APP_HEALTH_RECORDS_URL, APP_ORDER_URL, APP_REIMBURSEMENT_URL } from "../Constants";
import { HealthRecord_icon, MyOrder_icon } from "../Assets";

// import { useNavigate } from "react-router-dom";

function Header() {
  const [isSticky, setIsSticky] = useState(false);
  const { token, setToken } = useContext(AppContext);
  const [CorporateDetails, setCorporateDetails] = useState([]);
  const [logoPath, setLogoPath] = useState("");

  const {
    requestOptions,
    setRequestOptions,
    setEmployeeUserData,
    employeeUserData,
  } = useContext(AppContext);

  const navigate = useNavigate();
  const [appLinkToken, setappLinkToken] = useState(
    localStorage.getItem("appLinkToken")
  );

  async function handleTokenInResponse(response) {
    if (response.status === 401) {
      NotokenFound(navigate);
    } else {
      if (response.headers.has("Authorization")) {
        const authorizationToken = response.headers.get("Authorization");
        console.log("Found");
        localStorage.setItem("authorizationToken", authorizationToken);
        setRequestOptions((prevOptions) => ({
          ...prevOptions,
          headers: {
            ...prevOptions.headers,
            Authorization: authorizationToken,
          },
        }));
        setToken(response.headers.get("Authorization"));
        const updatedResponse = new Response(response.body, {
          ...response,
          headers: {
            ...response.headers,
            Authorization: authorizationToken,
          },
        });

        return updatedResponse;
      } else {
        return response;
      }
    }
  }

  useEffect(() => {
    if (token && mainAPikey) {
      async function CorporateDetails() {
        try {
          const response = await fetch(
            process.env.REACT_APP_API_URL +
              `/${mainAPikey}` +
              `/employee/get-corporate-details`,
            requestOptions
          );
          const tokenizedResponse = await handleTokenInResponse(response);
          const res = await tokenizedResponse.json();

          if (res.success) {
            setCorporateDetails(res?.data.corporate);
            setEmployeeUserData(res?.data?.employee);
            setLogoPath(res?.logo_path);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
      CorporateDetails();
    }
  }, [mainAPikey]);

  const EmptyToken = () => {
    localStorage.getItem("authorizationToken");
    const Empty_Token = localStorage.setItem("authorizationToken", "");
    setRequestOptions((prevOptions) => ({
      ...prevOptions,
      headers: {
        ...prevOptions.headers,
        Authorization: Empty_Token,
      },
    }));
    setToken(Empty_Token);
  };

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      // console.log(offset)
      if (offset + 20 >= 200) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    const debouncedScrollHandler = debounce(handleScroll, 50); // Adjust debounce time as needed

    window.addEventListener("scroll", debouncedScrollHandler);

    return () => {
      window.removeEventListener("scroll", debouncedScrollHandler);
    };
  }, []);

  // Debounce function
  function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        timeout = null;
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  return (
    <>
      <div
        className={`main-header-cover ${isSticky ? "nav-sticky active" : ""}`}
      >
        <Navbar className="d-lg-flex d-none">
          <div className="main-header-content">
            <Navbar.Brand href="#">
              {CorporateDetails[0]?.logo && (
                <img
                  src={`${logoPath}${CorporateDetails[0]?.logo}`}
                  className="broker-logo"
                ></img>
              )}
              <span class="Brand-title">
                {CorporateDetails[0]?.corporate_name}
              </span>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="my-2 my-lg-0"
                style={{ maxHeight: "100px" }}
                navbarScroll
              >
                <div
                  className={`nav-header ${
                    isSticky ? "nav-header custom-nav-header" : ""
                  }`}
                >
                  <div
                    className={
                      CorporateDetails[0]?.logo
                        ? "login-details-cover has-logo"
                        : "login-details-cover"
                    }
                  >
                    <h5>Hi, {employeeUserData?.name}</h5>
                    {appLinkToken && (
                      <>
                        <button
                          className="logout-btn"
                          style={{ marginRight: "10px" }}
                        >
                          <NavLink
                            className="outer-nav-links"
                            to={`${APP_ORDER_URL}?token=${appLinkToken}&href=${window.location.href}`}
                          >
                            <span className="logout-icons">
                            <img src={MyOrder_icon} alt="order-icon"/>
                            </span>
                            My Orders
                          </NavLink>
                        </button>
                        <button
                          className="logout-btn"
                          style={{ marginRight: "10px" }}
                        >
                          <NavLink
                            className="outer-nav-links"
                            to={`${APP_HEALTH_RECORDS_URL}?token=${appLinkToken}&href=${window.location.href}`}
                          >
                            <span className="logout-icons">
                            <img src={HealthRecord_icon} alt="health-icon"/>
                            </span>
                            Health Record
                          </NavLink>
                        </button>
                      </>
                    )}
                    {/* <div className="notification-box">
                      <IoNotificationsCircleSharp />
                    </div> */}
                    <button className="logout-btn" onClick={EmptyToken}>
                      <NavLink className="outer-nav-links" to="/">
                        <span className="logout-icons">
                          <LuLogOut />
                        </span>
                        Logout
                      </NavLink>
                    </button>
                  </div>
                </div>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>
       
      </div>
    </>
  );
}

export default Header;
